<template>
  <base-material-card
    icon="far fa-user-group"
    title="Beteiligte"
    color="primary"
  >
    <p>Eine mannschaftsweise Auflistung der Turner und Mannschaftsführer/Trainer. Kann für die Begrüßung für den Hallensprecher ausgedruckt werden.</p>
    <v-btn block @click="beteiligte(e, df)">Download</v-btn>
  </base-material-card>
</template>

<script>
import { usePdf } from '@/views/components/stb_w_2023/plugins/pdf'

export default {
  name: 'beteiligte',

  setup (props, context) {
    return {
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
